export type AppConfig = {
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    tourPath: string
    locale: string
    enableMock: boolean
}

let url;

if (process.env.NODE_ENV === 'production') {
    url = "https://api-pos-dev.aidi.sa"; // Set this in your production environment
} else {
    url = "https://api-pos-dev.aidi.sa"; // Set this in your development environment
}

const appConfig: AppConfig = {
    apiPrefix: url,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'ar',
    enableMock: false,
}

export default appConfig
