import {
    apiSignIn,
    apiSignOut,
    apiSignUp,
    apiOtp,
    apiForgotPassword,
    apiResetPassword,
} from '@/services/AuthService'
import {
    setUser,
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
} from '@/store'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import type {
    SignInCredential,
    SignUpCredential,
    Otp,
    ForgotPasswordReq,
    ResetPasswordReq,
} from '@/@types/auth'
import { useTranslation } from 'react-i18next'

type Status = 'success' | 'failed'

function useAuth() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useAppSelector((state) => state.auth.session)

    const signIn = async (
        values: SignInCredential
    ): Promise<
        | {
              status: Status
              message: string
          }
        | undefined
    > => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { token, otp_id } = resp.data

                if (token) {
                    dispatch(signInSuccess(token))
                    dispatch(setUser(resp.data))
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }

                if (otp_id) {
                    const redirectUrl = '/otp' + '?otp_id=' + otp_id //= query.get(REDIRECT_URL_KEY)
                    navigate(redirectUrl)
                    return {
                        status: 'success',
                        message: '',
                    }
                }
            }
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (errors: any) {
            return {
                status: 'failed',
                message: t('auth.failed_reg'),
            }
        }
    }

    const signUp = async (values: SignUpCredential) => {
        try {
            const resp = await apiSignUp(values)

            if (resp.data) {
                const { otp_id } = resp.data
                //dispatch(signUpSuccess(opt_id))

                const redirectUrl = '/otp' + '?otp_id=' + otp_id //= query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl)
                return {
                    status: 'success',
                    message: '',
                }
            }

            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (errors: any) {
            // const resp = await apiSignUp(values)

            return {
                status: 'failed',
                message: t('auth.failed_reg'),
            }
        }
    }

    const otp = async (values: Otp) => {
        try {
            const resp = await apiOtp(values)

            if (resp.data) {
                const { token } = resp.data

                if (token) {
                    dispatch(signInSuccess(token))
                    dispatch(setUser(resp.data))
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                } else {
                    return {
                        status: 'failed',
                        message: t('auth.failed_otp'),
                    }
                }
            }
        } catch (errors: any) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            // const resp = await apiSignUp(values)

            return {
                status: 'failed',
                message: t('auth.failed_reg'),
            }
        }
    }

    const ForgotPassword = async (values: ForgotPasswordReq) => {
        try {
            const resp = await apiForgotPassword(values)
            if (resp.data) {
                const { otp_id } = resp.data
                //dispatch(signUpSuccess(opt_id))

                const redirectUrl = '/reset-password' + '?otp_id=' + otp_id //= query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl)
                return {
                    status: 'success',
                    message: '',
                }
            } else {
                return {
                    status: 'failed',
                    message: t('auth.failed_reset_password'),
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: t('auth.failed_reset_password'),
            }
        }
    }

    const ResetPassword = async (values: ResetPasswordReq) => {
        try {
            const resp = await apiResetPassword(values)
            if (resp.data) {
                //dispatch(signUpSuccess(opt_id))

                navigate('sign-in')
                return {
                    status: 'success',
                    message: '',
                }
            } else {
                return {
                    status: 'failed',
                    message: t('auth.failed_reset_password'),
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: t('auth.failed_reset_password'),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(signOutSuccess())
        dispatch(
            setUser({
                avatar: '',
                userName: '',
                email: '',
                authority: [],
            })
        )
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        otp,
        ForgotPassword,
        ResetPassword,
    }
}

export default useAuth
