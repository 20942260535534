import ApiService from './ApiService'
import type {
    SignInCredential,
    SignUpCredential,
    ForgotPasswordReq,
    SignInResponse,
    SignUpResponse,
    Otp,
    ForgotPasswordRes,
    ResetPasswordReq,
} from '@/@types/auth'

export async function apiSignIn(data: SignInCredential) {
    return ApiService.fetchData<SignInResponse>({
        url: '/account/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data: SignUpCredential) {
    return ApiService.fetchData<SignUpResponse>({
        url: '/account/register',
        method: 'post',
        data,
    })
}

export async function apiOtp(data: Otp) {
    return ApiService.fetchData<SignInResponse>({
        url: '/account/validate-otp',
        method: 'post',
        data,
    })
}


export async function apiSignOut<T extends Record<string, unknown>>() {
    return ApiService.fetchData<T>({
        url: '/account/logout',
        method: 'post',
    })
}

export async function apiForgotPassword(data: ForgotPasswordReq) {
    return ApiService.fetchData<ForgotPasswordRes>({
        url: '/account/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data: ResetPasswordReq) {
    return ApiService.fetchData({
        url: '/account/reset-password',
        method: 'post',
        data,
    })
}
