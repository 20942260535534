/* eslint-disable import/default */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import * as Sentry from '@sentry/react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
} from 'react-router'
import { useNavigationType } from 'react-router-dom'

let sentryDsn;

if (process.env.NODE_ENV === 'production') {
    sentryDsn = "https://d2d233c61f92efdbaebb80b69e04912e@sentry.aidi.sa/4"; // Set this in your production environment
} else {
    sentryDsn = "https://d2d233c61f92efdbaebb80b69e04912e@sentry.aidi.sa/4"; // Set this in your development environment
}

console.log("Sentry DSN: " + sentryDsn);

Sentry.init({
    dsn: sentryDsn,
    integrations: [

        Sentry.browserTracingIntegration(),



        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,

        }),

        Sentry.browserProfilingIntegration(),

        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: true,
        }),
    ],


    //debug: false,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["api-pos-dev.aidi.sa", "api-pos.aidi.sa", "localhost", "dash.aidi.sa","dash-dev.aidi.sa"],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,


})



// TODO: #23 fix main.tsx:44 Warning: You are importing createRoot from "react-dom" which is not supported. You should instead import it from "react-dom/client".
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
